import { useQuery } from 'urql';
import { useRouter } from 'next/router';
// import customProtocolCheck from 'custom-protocol-check';
import { useTranslation } from 'next-i18next';
import {
  EMAIL,
  FACEBOOK_MESSENGER,
  TELEGRAM,
  VIBER,
} from 'src/common/constants';
import {
  GET_TELEGRAM_TOKEN,
  GET_VIBER_TOKEN,
  SUBSCRIBE_EMAIL,
  UNSUBSCRIBE_EMAIL,
  UNSUBSCRIBE_TELEGRAM,
  UNSUBSCRIBE_VIBER,
  UNSUBSCRIBE_FACEBOOK_MESSENGER,
  UPDATE_BOT_SUBSCRIPTION_SETTINGS,
} from '../gql/mutation';
import { BOT_SUBSCRIPTION, ME } from '../gql/query';
import { telegramBotName, viberBotName } from '../config';
import { throwErrorSentry } from '../utils/throwErrorSentry';
import { parseGqlError } from '../utils/error';
import useToast from './useToast';
import { useMutationUrql } from '../utils/urqlClient/helpers';

const useBotSettings = ({ profileId }) => {
  const { t } = useTranslation(['common']);
  const { showErrorToast, showSuccessToast } = useToast();
  const router = useRouter();

  const [me] = useQuery({ query: ME, pause: profileId });

  const userId = me?.data?.me?.id || profileId;

  const [subscriptions, reexecuteQuery] = useQuery({
    query: BOT_SUBSCRIPTION,
    pause: !userId,
  });

  const refetch = () => {
    reexecuteQuery({ requestPolicy: 'network-only' });
  };

  const viberSettings =
    subscriptions?.data?.botSubscriptions?.botSubscriptions?.find(
      el => el.messengerType === VIBER,
    );
  const fbMessengerSettings =
    subscriptions?.data?.botSubscriptions?.botSubscriptions?.find(
      el => el.messengerType === FACEBOOK_MESSENGER,
    );
  const telegramSettings =
    subscriptions?.data?.botSubscriptions?.botSubscriptions?.find(
      el => el.messengerType === TELEGRAM,
    );
  const emailSettings =
    subscriptions?.data?.botSubscriptions?.botSubscriptions?.find(
      el => el.messengerType === EMAIL,
    );

  const errorToast = () => {
    showErrorToast(t('errors-messages.default-error', { ns: 'common' }));
  };

  const [updateSettings, { loading: loadingUpdateSettings }] = useMutationUrql(
    UPDATE_BOT_SUBSCRIPTION_SETTINGS,
    {
      onCompleted: () => {
        showSuccessToast(t('use-bot-settings.settings-updated'));
        refetch();
      },
      onError: error => {
        throwErrorSentry(error);
        errorToast();
        refetch();
      },
    },
  );

  const [getViberToken, { loading: loadingGetViberToken }] = useMutationUrql(
    GET_VIBER_TOKEN,
    {
      onCompleted: data => {
        if (data?.getViberToken?.token) {
          router.push(
            `viber://pa?chatURI=${viberBotName}&context=subscribe-notification--${data?.getViberToken?.token}`,
          );
          // customProtocolCheck(
          //   `viber://pa?chatURI=${viberBotName}&context=${data?.getViberToken?.token}`,
          //   () => { setViber(false); }, () => { setViber(true); }, 3000,
          // );
        } else {
          errorToast();
        }
      },
      onError: error => {
        const parseError = parseGqlError(error?.graphQLErrors, t);
        if (parseError?.code !== 'SUBSCRIPTION_EXISTS') {
          errorToast();
          throwErrorSentry(error);
        }
        refetch();
      },
    },
  );

  const [subscribeEmailMutation, { loading: loadingSubscribeEmail }] =
    useMutationUrql(SUBSCRIBE_EMAIL, {
      onCompleted: () => {
        refetch();
      },
      onError: error => {
        const parseError = parseGqlError(error?.graphQLErrors, t);
        if (parseError?.code !== 'SUBSCRIPTION_EXISTS') {
          errorToast();
          throwErrorSentry(error);
        }
        refetch();
      },
    });

  const [unsubscribeViber, { loading: loadingUnsubscribeViber }] =
    useMutationUrql(UNSUBSCRIBE_VIBER, {
      onCompleted: () => {
        showSuccessToast(
          t('use-bot-settings.unsubscribe-messenger', { messenger: 'Viber' }),
        );
        refetch();
      },
      onError: error => {
        throwErrorSentry(error);
        errorToast();
        refetch();
      },
    });

  const [unsubscribeFbMessenger, { loading: loadingUnsubscribeFbMessenger }] =
    useMutationUrql(UNSUBSCRIBE_FACEBOOK_MESSENGER, {
      onCompleted: () => {
        showSuccessToast(
          t('use-bot-settings.unsubscribe-messenger', {
            messenger: 'Messenger',
          }),
        );
        refetch();
      },
      onError: error => {
        throwErrorSentry(error);
        errorToast();
        refetch();
      },
    });

  const [unsubscribeTelegram, { loading: loadingUnsubscribeTelegram }] =
    useMutationUrql(UNSUBSCRIBE_TELEGRAM, {
      onCompleted: () => {
        showSuccessToast(
          t('use-bot-settings.unsubscribe-messenger', {
            messenger: 'Telegram',
          }),
        );
        refetch();
      },
      onError: error => {
        throwErrorSentry(error);
        errorToast();
        refetch();
      },
    });

  const [unsubscribeEmail, { loading: loadingUnsubscribeEmail }] =
    useMutationUrql(UNSUBSCRIBE_EMAIL, {
      onCompleted: () => {
        showSuccessToast(t('use-bot-settings.unsubscribe-email'));
        refetch();
      },
      onError: error => {
        throwErrorSentry(error);
        errorToast();
        refetch();
      },
    });

  const [getTelegramToken, { loading: loadingGetTelegramToken }] =
    useMutationUrql(GET_TELEGRAM_TOKEN, {
      onCompleted: data => {
        if (data?.getTelegramToken?.token) {
          router.push(
            `tg://resolve?domain=${telegramBotName}&start=subscribe-notification--${data?.getTelegramToken?.token}`,
          );
          // TODO нужно проверить почему не работает
          // customProtocolCheck(
          //   `tg://resolve?domain=${telegramBotName}&start=${data?.getTelegramToken?.token}`,
          //   () => { setTelegram(false); }, () => { setTelegram(true); }, 3000,
          // );
        } else {
          errorToast();
        }
      },
      onError: error => {
        const parseError = parseGqlError(error?.graphQLErrors, t);
        if (parseError?.code !== 'SUBSCRIPTION_EXISTS') {
          errorToast();
          throwErrorSentry(error);
        }
        refetch();
      },
    });

  const updateMessengerSettings = (
    id,
    { newMessage, newOrder, newOrderStatus },
  ) => {
    if (profileId)
      updateSettings({
        input: {
          id,
          newMessage,
          newOrder,
          newOrderStatus,
        },
      });
  };

  const subscribeEmail = email => {
    if (profileId)
      subscribeEmailMutation({
        input: {
          email,
          userId: profileId,
        },
      });
  };

  return {
    getViberToken: () => {
      if (profileId) getViberToken({});
    },
    loadingGetViberToken,
    getTelegramToken: () => {
      if (profileId) getTelegramToken({});
    },
    loadingGetTelegramToken,
    viberSettings,
    emailSettings,
    telegramSettings,
    subscribeEmail,
    loadingSubscribeEmail,
    unsubscribeViber: () => {
      if (profileId) unsubscribeViber({});
    },
    loadingUnsubscribeViber,
    unsubscribeTelegram: () => {
      if (profileId) unsubscribeTelegram({});
    },
    loadingUnsubscribeTelegram,
    unsubscribeEmail: () => {
      if (profileId) unsubscribeEmail({});
    },
    unsubscribeFbMessenger: () => {
      if (profileId) unsubscribeFbMessenger({});
    },
    loadingUnsubscribeEmail,
    updateMessengerSettings,
    loadingUpdateMessengerSettings: loadingUpdateSettings,
    loading: me?.fetching || subscriptions?.fetching,
    fbMessengerSettings,
    loadingUnsubscribeFbMessenger,
    refetch,
  };
};

export default useBotSettings;
