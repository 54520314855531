import React from 'react';
import { Flex, Text, Heading, Box } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useTranslation } from 'next-i18next';
import BotSubscriptionButtons from '../BotSubscriptionButtons';

const BotSubscription = ({ position = 'start', ...other }) => {
  const { t } = useTranslation(['common']);

  return (
    <Box {...other}>
      <Heading as='h2' size='2-4xl' pt={[4, 0, 0]} textAlign={position}>
        {t('bot-info.bot-subscription.title')}
      </Heading>

      <Flex mt={5}>
        <CheckIcon boxSize={4} mr={2} mt={1.5} />
        <Box textAlign={position}>
          <Text as='div' fontWeight='semibold'>
            {t('bot-info.bot-subscription.item-1-title')}
          </Text>
          <Text as='div'>
            {t('bot-info.bot-subscription.item-1-description')}
          </Text>
        </Box>
      </Flex>
      <Flex mt={5}>
        <CheckIcon boxSize={4} mr={2} mt={1.5} />
        <Box textAlign={position}>
          <Text as='div' fontWeight='semibold'>
            {t('bot-info.bot-subscription.item-2-title')}
          </Text>
          <Text as='div'>
            {t('bot-info.bot-subscription.item-2-description')}
          </Text>
        </Box>
      </Flex>

      <BotSubscriptionButtons position={position} />
    </Box>
  );
};

export default BotSubscription;
