import React, { useEffect, useState } from 'react';
import { Box, Flex, Spinner, Button, Img, Heading } from '@chakra-ui/react';
import { GET_FACEBOOK_MESSENGER_TOKEN } from 'src/gql/mutation';
import { useMutationUrql } from 'src/utils/urqlClient/helpers';
import { throwErrorSentry } from 'src/utils/throwErrorSentry';
import { parseGqlError } from 'src/utils/error';
import { useTranslation } from 'next-i18next';
import useToast from 'src/hooks/useToast';
import { fbMessengerBotName } from 'src/config';
import { MessengerIcon } from 'src/icons';
import useModalPaddings from 'src/hooks/useModalPaddings';
import Modal from '../Modal';
import NextLink from '../NextLink';

const styleHeading = {
  as: 'h3',
  size: '1-2xl',
  textAlign: 'center',
};
const styleContainer = {
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between',
  minH: 50,
};
const styleButton = img => ({
  variant: 'primary',
  mt: 5,
  leftIcon: img && <MessengerIcon />,
});

const styleSpinner = {
  thickness: '4px',
  speed: '0.65s',
  emptyColor: 'gray.100',
  color: 'brand.primary',
  size: 'xl',
};

const FacebookSubscriptionModal = ({ isOpen, onClose, refetch }) => {
  const { t } = useTranslation(['common']);
  const { showErrorToast } = useToast();
  const errorToast = () => {
    showErrorToast(t('errors-messages.default-error', { ns: 'common' }));
  };
  const [token, setToken] = useState();
  const [getToken, { loading }] = useMutationUrql(
    GET_FACEBOOK_MESSENGER_TOKEN,
    {
      onCompleted: data => {
        if (data?.getFbMessengerToken?.token) {
          setToken(data?.getFbMessengerToken?.token);
        } else {
          errorToast();
        }
      },
      onError: error => {
        const parseError = parseGqlError(error?.graphQLErrors, t);
        if (parseError?.code !== 'SUBSCRIPTION_EXISTS') {
          errorToast();
          throwErrorSentry(error);
        }
        onClose();
        refetch();
      },
    },
  );

  useEffect(() => {
    if (isOpen) getToken();
  }, [isOpen]);

  const boxPaddings = useModalPaddings();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Box {...boxPaddings} bg='brand.secondaryBackground'>
        <Flex {...styleContainer}>
          {loading ? (
            <Spinner {...styleSpinner} />
          ) : (
            <>
              <Heading {...styleHeading}>
                {t('bot-info.bot-subscription.go-to-messenger-title')}
              </Heading>

              <Img
                src='deployment/images/facebookSubscription.jpg'
                borderRadius='lg'
                mt={5}
              />
              <NextLink
                href={`https://m.me/${fbMessengerBotName}/?ref=subscribe-notification--${token}`}
                target='_blank'
              >
                <Button
                  {...styleButton(MessengerIcon)}
                  onClick={() => {
                    onClose();
                  }}
                >
                  {t('bot-info.bot-subscription.go-to-messenger')}
                </Button>
              </NextLink>
            </>
          )}
        </Flex>
      </Box>
    </Modal>
  );
};

export default FacebookSubscriptionModal;
