import React from 'react';
import { Text, Button } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { useTranslation } from 'next-i18next';
import { SUBSCRIBED } from '../../common/constants';

const styleButton = {
  variant: 'primary',
  mt: 5,
  w: ['100%', 66, 66],
};

const BotSubscriptionButton = ({
  messenger,
  Icon,
  getToken,
  messengerSettings,
  loading,
}) => {
  const { t } = useTranslation(['common']);
  const buttonText = `${t(
    'bot-info.bot-subscription.subscribe-to',
  )} ${messenger}`;
  return (
    <>
      {messengerSettings?.subscriptionStatus !== SUBSCRIBED ? (
        <Button
          {...styleButton}
          leftIcon={<Icon />}
          data-testid={`subscribe-to-${messenger}`}
          onClick={getToken}
          isLoading={loading}
          loadingText={buttonText}
        >
          {buttonText}
        </Button>
      ) : (
        <Text my={3}>
          <CheckIcon boxSize={3} mr={2} />
          {messenger} - {t('bot-info.bot-subscription.you-subscribed')}
        </Text>
      )}
    </>
  );
};

export default BotSubscriptionButton;
