import React, { useState } from 'react';
import { Flex, Button } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { routerPush } from 'src/utils/urlControl';
import useProfile from 'src/hooks/useProfile';
import { ViberIcon, MessengerIcon, TelegramIcon } from 'src/icons';
import { fbMessengerBotName, telegramBotName, viberBotName } from 'src/config';
import useBotSettings from '../../hooks/useBotSettings';
import { EXECUTOR, SUBSCRIBED } from '../../common/constants';
import BotSubscriptionButton from '../BotSubscriptionButton';
import FacebookSubscriptionModal from '../FacebookSubscriptionModal';

const BotSubscriptionButtons = ({
  position = 'start',
  showSettings = true,
}) => {
  const { t } = useTranslation(['common']);
  const router = useRouter();
  const { profileId, userStatus } = useProfile();
  const [isOpenFacebookModal, setOpenFacebookModal] = useState(false);

  const {
    viberSettings,
    telegramSettings,
    getViberToken,
    getTelegramToken,
    loadingGetTelegramToken,
    loadingGetViberToken,
    fbMessengerSettings,
    refetch,
  } = useBotSettings({ profileId });

  const isShowSettings =
    (telegramSettings?.subscriptionStatus === SUBSCRIBED ||
      viberSettings?.subscriptionStatus === SUBSCRIBED) &&
    showSettings;

  return (
    <Flex flexDirection='column' alignItems={position} mt={5}>
      {telegramBotName && (
        <BotSubscriptionButton
          messenger='Telegram'
          Icon={TelegramIcon}
          getToken={getTelegramToken}
          messengerSettings={telegramSettings}
          loading={loadingGetTelegramToken}
        />
      )}
      {fbMessengerBotName && (
        <>
          <FacebookSubscriptionModal
            isOpen={isOpenFacebookModal}
            onClose={() => setOpenFacebookModal(false)}
            refetch={refetch}
          />
          <BotSubscriptionButton
            messenger='Messenger'
            Icon={MessengerIcon}
            getToken={() => setOpenFacebookModal(true)}
            messengerSettings={fbMessengerSettings}
          />
        </>
      )}
      {userStatus !== EXECUTOR && viberBotName && (
        <BotSubscriptionButton
          messenger='Viber'
          Icon={ViberIcon}
          getToken={getViberToken}
          messengerSettings={viberSettings}
          loading={loadingGetViberToken}
        />
      )}

      {isShowSettings && (
        <Button
          variant='link'
          w='min'
          my={3}
          onClick={() =>
            routerPush(
              router,
              '/profile?index=settings&scroll=true',
              undefined,
              { scroll: false },
            )
          }
        >
          {t('button.notification-settings')}
        </Button>
      )}
    </Flex>
  );
};

export default BotSubscriptionButtons;
